@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext');

.clickable {
    cursor: pointer;
}
  
.text-input {
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
    margin: 5px 0;
    border-radius: 10px;
}

.game-title {
    font-size: 80px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: rgb(85, 70, 52);
}

.center-text {
    text-align: center
}
  
.flex-row {
    display: flex;
    flex-direction: row;
}
  
.flex-column {
    display: flex;
    flex-direction: column;
}
  
.base-text {
    font-family: 'Poppins', sans-serif;
    color: #d8d8d8;
}

.pos-absolute {
    position: absolute;
}
  
.create-bt-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.lobbies-text {
    font-size: 70px;
    font-weight: bold;
}

.no-lobbies {
    font-size: 30px;
}

.lobby-code {
    font-weight: bold;
    font-size: 30px;
}

.create-bt {
    padding: 10px;
    margin: 5px 0;
}

.join-bt {
    width: 100%;
}

.center-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-page-container {
    background-color: #ad906b;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.landing-page-container {
    width: 30vw;
    border-radius: 10px;
    border-width: 10px;
    border-style: solid;
    border-color: rgb(110 85 55);
    background-color: burlywood;
    color: rgb(85, 70, 52);
    padding: 40px;
    box-shadow: 15px 20px 17px 3px;;
}

.base-button {
    width: 100%;
    border-radius: 10px;
    background: rgb(85, 70, 52);
    font-family: 'Poppins', sans-serif;
    font-size: 25px;
}

.base-button:hover {
	cursor: pointer;
	background-color: rgb(168, 140, 103);
	color: rgb(85, 70, 52);
    font-weight: bold;
}

@media screen and (max-width: 1600px){
    .lobbies-text {
        font-size: 60px;
    }

    .no-lobbies {
        font-size: 25px;
    }

    .text-input {
        font-size: 20px;
    }

    .base-button {
        font-size: 20px;
    }

    .lobby-code {
        font-size: 25px;
    }
}
  
@media screen and (max-width: 1500px){
    .landing-page-container {
        width: 30vw;
    }
}

@media screen and (max-width: 1200px){
    .landing-page-container {
        width: 40vw;
    }
}

@media screen and (max-width: 1000px){
    .landing-page-container {
        width: 50vw;
    }
}

@media screen and (max-width: 800px){
    .landing-page-container {
        width: 60vw;
    }
}

@media screen and (max-width: 600px){
    .lobbies-text {
        font-size: 50px;
    }

    .no-lobbies {
        font-size: 20px;
    }

    .text-input {
        font-size: 15px;
    }

    .base-button {
        font-size: 15px;
    }

    .lobby-code {
        font-size: 20px;
    }
}

@media screen and (max-width: 400px){
    .lobbies-text {
        font-size: 40px;
    }

    .no-lobbies {
        font-size: 15px;
    }

    .text-input {
        font-size: 10px;
    }

    .base-button {
        font-size: 10px;
    }

    .lobby-code {
        font-size: 20px;
    }
    
    .game-title {
        font-size: 60px;
    }
}