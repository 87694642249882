.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-content: center;
	height: 100vh;
    background: #ad906b;
}

.table-container {
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.board {
	border-color: brown;
	border-style: groove;
	border-width: 8px;
	box-shadow: 3px 5px 8px 0px;
}

.board-plate {
	gap: 10px;
    display: flex;
    flex-direction: column;
	background-color: peru;
}

.table-row {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.highlight-green {
	background-color: limegreen !important;
	color: rgb(27, 114, 27) !important;
}

.highlight-red {
	background-color: red !important;
	color: rgb(146, 0, 0) !important;
}

.text-green {
	color: limegreen;
}

.text-red {
	color: red;
}

.table-cell {
    width: 3vw;
    height: 3vw;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
	color: rgb(167, 137, 98);
	background-color: burlywood;
}

.table-cell:hover {
	cursor: pointer;
}

.tile-black {
	background-color: black;
	color: inherit;
    box-shadow: 1px 2px;
}

.tile-white {
	background-color: white;
	color: inherit;
    box-shadow: 1px 2px 1px 0px;
}

.tile {
	width: 2vw;
	height: 2vw;
	border-radius: 50px;
}

.tile-large {
	width: 3vw;
	height: 3vw;
	border-radius: 50px;
    color: #705e46;
}

.info-message-container-local {
	display: flex;
	flex-direction: row;
	white-space: pre-wrap;
	justify-content: space-between;
    text-align: center;
}

.turn-container {
	font-size: 30px;
	text-align: center;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-center {
	display: flex;
    justify-content: center !important;
    align-content: center !important;
    align-items: center !important;
}

.pre-wrap {
	white-space: pre-wrap;
}

.justify-space-between {
	justify-content: space-between !important;
}

.ten-p {
	/*width: 10%;*/
}

.ninety-p {
	width: 90%;;
}

@media screen and (max-width: 1400px){
  .table-cell {
	  width: 4vw;
	  height: 4vw;
  }

  .tile {
	  width: 3vw;
	  height: 3vw;
  }
}

@media screen and (max-width: 1200px){
	.table-cell {
		width: 5vw;
		height: 5vw;
	}

	.tile {
		width: 3.5vw;
		height: 3.5vw;
	}
  }

  @media screen and (max-width: 950px){
	.table-cell {
		width: 6vw;
		height: 6vw;
	}

	.tile {
		width: 4vw;
		height: 4vw;
	}

	.info-message-container-local {
		font-size: 14px;
		transition: 0.8s all ease;
	}
  }

  @media screen and (max-width: 770px){
	.board-plate {
		gap: 8px;
		transition: 0.8s all ease;
	}
	
	.table-row {
		gap: 8px;
		transition: 0.8s all ease;
	}

	.info-message-container-local {
		font-size: 12px;
		transition: 0.8s all ease;
	}
  }

  @media screen and (max-width: 650px){
	.table-cell {
		width: 9vw;
		height: 9vw;
	}

	.tile {
		width: 6vw;
		height: 6vw;
	}

	.ninety-p {
		width: 90%;;
		font-size: 50px;
		transition: 0.8s all ease;
	}

	.info-message-container-local {
		font-size: 14px;
		transition: 0.8s all ease;
	}
  }

  @media screen and (max-width: 450px){
	.board-plate {
		gap: 6px;
		transition: 0.8s all ease;
	}
	
	.table-row {
		gap: 6px;
		transition: 0.8s all ease;
	}

	.info-message-container-local {
		font-size: 12px;
		transition: 0.8s all ease;
	}
  }

  @media screen and (max-width: 400px){
	.table-cell {
		width: 9vw;
		height: 9vw;
	}

	.tile {
		width: 6vw;
		height: 6vw;
	}

	.ninety-p {
		width: 90%;;
		font-size: 40px;
		transition: 0.8s all ease;
	}

	.board-plate {
		gap: 4px;
		transition: 0.8s all ease;
	}
	
	.table-row {
		gap: 4px;
		transition: 0.8s all ease;
	}
  }