@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext');

.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	align-content: center;
	font-family: 'Poppins', sans-serif;
	height: 100vh;
    background: #ad906b;
}

.table-container {
	display: flex;
	align-content: center;
	justify-content: center;
	align-items: center;
}

.player-container {
	border-radius: 10px;
    width: fit-content;
}

.align-items-right {
	align-items: flex-end;
}

.player-black {
	background-color: black;
	color: aliceblue;
	padding: 0 10px;
}

.player-white {
	background-color: aliceblue;
	color: black;
	text-align: right;
	padding: 0 10px;
}

.gain-container {
	margin-top: 20px;
	width: 100%;
}

.align-right {
	text-align: right;
}

.text-title {
	font-size: 35px;
	font-weight: bold;
}

.turn-text {
	font-size: 60px;
	font-weight: bold;
}

.game-code-title {
	font-size: 30px;
	font-weight: bold;
}

.game-code {
	font-size: 30px;
	background-color: rgb(85, 70, 52);
	padding: 0 5px;
	border-radius: 10px;
	color: rgb(226, 214, 198);
}

.game-code:hover {
	cursor: pointer;
	background-color: burlywood;
	color: rgb(85, 70, 52);
}

.end-game-bt {
    padding: 0 10px;
	margin-top: 20px;
}

.waiting-text {
	font-size: 35px;
	font-weight: bold;
}

.players-title {
	font-size: 30px;
	font-weight: bold;
}

.board {
	border-color: brown;
	border-style: groove;
	border-width: 8px;
	box-shadow: 3px 5px 8px 0px;
}

.board-plate {
	gap: 10px;
    display: flex;
    flex-direction: column;
	background-color: peru;
}

.table-row {
	display: flex;
	flex-direction: row;
	align-content: center;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.highlight-green {
	background-color: limegreen !important;
	color: rgb(27, 114, 27) !important;
}

.highlight-red {
	background-color: red !important;
	color: rgb(146, 0, 0) !important;
}

.text-green {
	color: limegreen;
}

.text-red {
	color: red;
}

.table-cell {
    width: 3vw;
    height: 3vw;
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
	color: rgb(167, 137, 98);
	background-color: burlywood;
}

.table-cell:hover {
	cursor: pointer;
}

.tile-black {
	background-color: black;
	color: inherit;
    box-shadow: 1px 2px;
}

.tile-white {
	background-color: white;
	color: inherit;
    box-shadow: 1px 2px 1px 0px;
}

.tile {
	width: 2vw;
	height: 2vw;
	border-radius: 50px;
}

.tile-large {
	width: 3vw;
	height: 3vw;
	border-radius: 50px;
    color: #705e46;
}

.info-message-container {
	position: absolute;
	display: flex;
	flex-direction: row;
	white-space: pre-wrap;
    text-align: center;
	background-color: rgb(179, 3, 3);
	color: aliceblue;
	border-radius: 20px;
	border-style: dotted;
	border-width: 6px;
	border-color: red;
	margin: 0 10px;
	padding: 0 10px;
	font-size: 30px;
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgb(255, 0, 0);
	}

	70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.404);
	}
}

.turn-container {
	font-size: 30px;
	text-align: center;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-center {
	display: flex;
    justify-content: center !important;
    align-content: center !important;
    align-items: center !important;
}

.pre-wrap {
	white-space: pre-wrap;
}

.justify-space-between {
	justify-content: space-between !important;
}

.ten-p {
	/*width: 10%;*/
}

.ninety-p {
	width: 90%;;
}

@media screen and (max-width: 1400px){
  .table-cell {
	  width: 4vw;
	  height: 4vw;
  }

  .tile {
	  width: 3vw;
	  height: 3vw;
  }

  .waiting-text {
	font-size: 30px;
}
}

@media screen and (max-width: 1200px){
	.table-cell {
		width: 5vw;
		height: 5vw;
	}

	.tile {
		width: 3.5vw;
		height: 3.5vw;
	}
  }

  @media screen and (max-width: 950px){
	.table-cell {
		width: 6vw;
		height: 6vw;
	}

	.tile {
		width: 4vw;
		height: 4vw;
	}

	.turn-text {
		font-size: 50px;
		font-weight: bold;
	}
  }

  @media screen and (max-width: 770px){
	.board-plate {
		gap: 8px;
	}
	
	.table-row {
		gap: 8px;
	}

	.waiting-text {
		font-size: 25px;
	}

  }

  @media screen and (max-width: 650px){
	.table-cell {
		width: 9vw;
		height: 9vw;
	}

	.tile {
		width: 6vw;
		height: 6vw;
	}

	.ninety-p {
		width: 90%;;
		font-size: 50px;
	}

  }

  @media screen and (max-width: 450px){
	.board-plate {
		gap: 6px;
	}
	
	.table-row {
		gap: 6px;
	}

	.info-message-container {
		font-size: 18px;
	}

	.text-title {
		font-size: 30px;
	}

	.game-code-title {
		font-size: 25px;
	}
	
	.turn-text {
		font-size: 45px;
		font-weight: bold;
	}

	.end-game-bt {
		padding: 2px 10px !important;
	}

	.waiting-text {
		font-size: 20px;
	}

  }

  @media screen and (max-width: 430px){
	.table-cell {
		width: 9vw;
		height: 9vw;
	}

	.tile {
		width: 6vw;
		height: 6vw;
	}

	.ninety-p {
		width: 90%;;
		font-size: 40px;
	}

	.board-plate {
		gap: 4px;
	}
	
	.table-row {
		gap: 4px;
	}

	.text-title {
		font-size: 20px;
	}

	.game-code {
		font-size: 20px;
	}

	.turn-text {
		font-size: 35px !important;
		font-weight: bold;
	}

	.info-message-container {
		font-size: 18px;
	}
  }